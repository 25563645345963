import React, { useEffect, useRef, useState, ReactNode } from 'react';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm, Controller, useFieldArray, useWatch } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import toast from 'react-hot-toast';
import SAlert from '../../../components/SAlert';
import moment from 'moment';
import { currencyFormat } from '../../../resources/helper';
import InputGroup from 'react-bootstrap/InputGroup';
import CurrencyInput from 'react-currency-input-field';
import DatePicker, { ReactDatePicker } from "react-datepicker";
import '../../../assets/css/datepicker.css'
import { TransactionAdjustmentFormI } from '../../../resources/form-props';
import { SELECT_CUSTOM_STYLE } from '../../../resources/constants';
import SelectCustomOption from '../../../components/SelectCustomOption';
import { yupResolver } from '@hookform/resolvers/yup';
import { CreateTransactionAdjustmentValidate } from '../../../resources/form-validator';

interface CreateTransactionAdjustmentModalProps {
    show: boolean;
    onHide: () => void;
    onSave: (data: any) => void;
    glAccounts: any[];
    filterAllAccountList: (inputValue: string) => Promise<any[]>;
    selectedCalendar: any
}


const CreateTransactionAdjustmentModal: React.FC<CreateTransactionAdjustmentModalProps> = ({
    show, onHide, onSave, glAccounts, filterAllAccountList, selectedCalendar
}) => {
    const calendarRef = useRef<(ReactDatePicker | null)[]>([]);
    const [showAlert, setShowAlert] = useState<boolean>(false);
    const [formData, setFormData] = useState<any>();
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#d1ffbd'));

    const { handleSubmit, control, formState: { errors }, reset, setValue, watch, trigger } = useForm<TransactionAdjustmentFormI>({
        defaultValues: {
            transaction_adjustment: [{
                description: '',
                transaction_at: new Date(selectedCalendar.year, selectedCalendar.month - 1, 1),
                adjustment_debit: 0,
                adjustment_credit: 0,
                adjustment_master_debit_account_id: null,
                adjustment_master_credit_account_id: null,
            }]
        },
        resolver: yupResolver(CreateTransactionAdjustmentValidate),
        mode: 'onChange',
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "transaction_adjustment", // unique name for your Field Array
    });

    const onSubmit = (data: any) => {
        setFormData(data);
        setShowAlert(true);
    };

    const processData = () => {
        setShowAlert(false);
        onSave(formData['transaction_adjustment']);
        reset();
        onHide();
    }

    const submitForm = () => {
        handleSubmit(onSubmit)();
        // setShowAlert(true);
    };

    const handleClose = () => {
        reset();
        onHide();
    };

    const openCalendar = (index: number) => {
        if (calendarRef.current && calendarRef.current[index]) {
            calendarRef.current[index]?.setOpen(true)
        }
    }

    const _handleAddMore = () => {
        append({
            description: '',
            transaction_at: new Date(selectedCalendar.year, selectedCalendar.month - 1, 1),
            adjustment_debit: 0,
            adjustment_credit: 0,
            adjustment_master_debit_account_id: null,
            adjustment_master_credit_account_id: null,
        });
    }

    const _handleRemove = (index: number) => {
        remove(index);
        calendarRef.current.splice(index, 1);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLFormElement>) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            event.preventDefault();
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size='xl' className='modal-custom-xxl' animation={true} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>Enter Transaction Adjustment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onKeyDown={handleKeyDown} className="create_adjustment_modal">
                    <div className='col-sm-12 text-end mb-3'>
                        <button className='btn btn-info' type='button' onClick={_handleAddMore}>
                            Add More &nbsp;
                            <i className='fa fa-plus'></i>
                        </button>
                    </div>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Date</th>
                                <th>Description</th>
                                <th>Debit Amount</th>
                                <th>Debit GL Account</th>
                                <th>Credit Amount</th>
                                <th>Credit GL Account</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                fields.map((field: any, index: number) => (
                                    <tr key={field.id}>
                                        <td className="text-start" width={"15%"}>
                                            <Form.Group className="col-sm-12 date-picker-container" style={{ position: 'relative' }}>
                                                <Controller
                                                    name={`transaction_adjustment.${index}.transaction_at`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <DatePicker
                                                            ref={(el) => calendarRef.current[index] = el}
                                                            name={field.name}
                                                            selected={field.value}
                                                            onChange={field.onChange}
                                                            // showYearDropdown
                                                            // scrollableYearDropdown
                                                            // yearDropdownItemNumber={40}
                                                            className="form-control"
                                                            dateFormat="MM/dd/yyyy"
                                                            minDate={moment(`${selectedCalendar.year}/${selectedCalendar.month}/01`, "YYYY/MM/DD").toDate()}
                                                            maxDate={moment(`${selectedCalendar.year}/${selectedCalendar.month}/01`, "YYYY/MM/DD").endOf('month').toDate()}
                                                        />
                                                    )}
                                                />
                                                <i
                                                    className="fa fa-calendar fa-xl"
                                                    aria-hidden="true"
                                                    onClick={() => openCalendar(index)}
                                                    style={{
                                                        position: 'absolute',
                                                        right: '10px',
                                                        top: '50%',
                                                        transform: 'translateY(-50%)',
                                                        cursor: 'pointer',
                                                    }}
                                                ></i>
                                            </Form.Group>
                                            {
                                                errors && errors.transaction_adjustment && errors.transaction_adjustment[index] && errors.transaction_adjustment[index]?.transaction_at && errors.transaction_adjustment[index]?.transaction_at?.message && (
                                                    <Form.Text className="d-flex text-danger">
                                                        <strong>
                                                            {errors.transaction_adjustment[index]?.transaction_at?.message as ReactNode || ''}
                                                        </strong>
                                                    </Form.Text>
                                                ) || ''
                                            }
                                        </td>
                                        <td width={"20%"}>
                                            <Form.Group className="col-sm-12">
                                                <Controller
                                                    name={`transaction_adjustment.${index}.description`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <textarea
                                                            {...field}
                                                            placeholder="Enter Description"
                                                            className="form-control"
                                                            rows={2}
                                                        />
                                                        // <Form.Control
                                                        //     {...field}
                                                        //     type="text"
                                                        //     placeholder="Enter Description"
                                                        //     className="form-control"
                                                        // />
                                                    )}
                                                />
                                            </Form.Group>
                                            {
                                                errors && errors.transaction_adjustment && errors.transaction_adjustment[index] && errors.transaction_adjustment[index]?.description && errors.transaction_adjustment[index]?.description?.message && (
                                                    <Form.Text className="d-flex text-danger">
                                                        <strong>
                                                            {errors.transaction_adjustment[index]?.description?.message as ReactNode || ''}
                                                        </strong>
                                                    </Form.Text>
                                                ) || ''
                                            }
                                        </td>
                                        <td width={"10%"}>
                                            <Form.Group className="col-sm-12 d-flex justify-content-center align-items-center">
                                                $ &nbsp;
                                                <Controller
                                                    name={`transaction_adjustment.${index}.adjustment_debit`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <CurrencyInput
                                                            className='form-control'
                                                            placeholder="0.00"
                                                            name={field.name}
                                                            onValueChange={(value) => {
                                                                setValue(field.name, (value && +value) || 0);
                                                                trigger(`transaction_adjustment.${index}.adjustment_debit`);
                                                                trigger(`transaction_adjustment.${index}.adjustment_credit`);
                                                            }}
                                                            allowDecimals
                                                            decimalsLimit={2}
                                                            decimalSeparator="."
                                                            groupSeparator=","
                                                            disableAbbreviations
                                                            allowNegativeValue={true}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            {
                                                errors && errors.transaction_adjustment && errors.transaction_adjustment[index] && errors.transaction_adjustment[index]?.adjustment_debit && errors.transaction_adjustment[index]?.adjustment_debit?.message && (
                                                    <Form.Text className="d-flex text-danger">
                                                        <strong>
                                                            {errors.transaction_adjustment[index]?.adjustment_debit?.message as ReactNode || ''}
                                                        </strong>
                                                    </Form.Text>
                                                ) || ''
                                            }
                                        </td>
                                        <td width={"20%"}>
                                            <Form.Group className="col-sm-12">
                                                <Controller
                                                    control={control}
                                                    name={`transaction_adjustment.${index}.adjustment_master_debit_account_id`}
                                                    render={({ field: { value, onChange, onBlur } }) => (
                                                        <AsyncSelect
                                                            isClearable={true}
                                                            placeholder="Please select GL acc"
                                                            defaultOptions={glAccounts}
                                                            loadOptions={filterAllAccountList}
                                                            onChange={(selectedOption) => {
                                                                onChange(selectedOption);
                                                            }}
                                                            className=""
                                                            menuPosition="fixed"
                                                            value={value}
                                                            defaultValue={value}
                                                            styles={selectCustomStyle}
                                                            components={{ Option: SelectCustomOption }}
                                                        />
                                                    )}
                                                />

                                            </Form.Group>
                                            {
                                                errors && errors.transaction_adjustment && errors.transaction_adjustment[index] && errors.transaction_adjustment[index]?.adjustment_master_debit_account_id && errors.transaction_adjustment[index]?.adjustment_master_debit_account_id?.message && (
                                                    <Form.Text className="d-flex text-danger">
                                                        <strong>
                                                            {errors.transaction_adjustment[index]?.adjustment_master_debit_account_id?.message as ReactNode || ''}
                                                        </strong>
                                                    </Form.Text>
                                                ) || ''
                                            }
                                        </td>
                                        <td width={"10%"}>
                                            <Form.Group className="col-sm-12 d-flex justify-content-center align-items-center">
                                                $ &nbsp;
                                                <Controller
                                                    name={`transaction_adjustment.${index}.adjustment_credit`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <CurrencyInput
                                                            className='form-control'
                                                            placeholder="0.00"
                                                            name={field.name}
                                                            onValueChange={(value) => {
                                                                setValue(field.name, (value && +value) || 0);
                                                                trigger(`transaction_adjustment.${index}.adjustment_debit`);
                                                                trigger(`transaction_adjustment.${index}.adjustment_credit`);
                                                            }}
                                                            allowDecimals
                                                            decimalsLimit={2}
                                                            decimalSeparator="."
                                                            groupSeparator=","
                                                            disableAbbreviations
                                                            allowNegativeValue={true}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            {
                                                errors && errors.transaction_adjustment && errors.transaction_adjustment[index] && errors.transaction_adjustment[index]?.adjustment_credit && errors.transaction_adjustment[index]?.adjustment_credit?.message && (
                                                    <Form.Text className="d-flex text-danger">
                                                        <strong>
                                                            {errors.transaction_adjustment[index]?.adjustment_credit?.message as ReactNode || ''}
                                                        </strong>
                                                    </Form.Text>
                                                ) || ''
                                            }
                                        </td>
                                        <td width={"20%"}>
                                            <Form.Group className="col-sm-12">
                                                <Controller
                                                    control={control}
                                                    name={`transaction_adjustment.${index}.adjustment_master_credit_account_id`}
                                                    render={({ field: { value, onChange, onBlur } }) => (
                                                        <AsyncSelect
                                                            isClearable={true}
                                                            placeholder="Please select GL acc"
                                                            defaultOptions={glAccounts}
                                                            loadOptions={filterAllAccountList}
                                                            onChange={(selectedOption) => {
                                                                onChange(selectedOption);
                                                            }}
                                                            className=""
                                                            menuPosition="fixed"
                                                            value={value}
                                                            defaultValue={value}
                                                            styles={selectCustomStyle}
                                                            components={{ Option: SelectCustomOption }}
                                                        />
                                                    )}
                                                />

                                            </Form.Group>
                                            {
                                                errors && errors.transaction_adjustment && errors.transaction_adjustment[index] && errors.transaction_adjustment[index]?.adjustment_master_credit_account_id && errors.transaction_adjustment[index]?.adjustment_master_credit_account_id?.message && (
                                                    <Form.Text className="d-flex text-danger">
                                                        <strong>
                                                            {errors.transaction_adjustment[index]?.adjustment_master_credit_account_id?.message as ReactNode || ''}
                                                        </strong>
                                                    </Form.Text>
                                                ) || ''
                                            }

                                        </td>
                                        <td width={"5%"} className='text-center'>
                                            {
                                                fields.length > 1 && (
                                                    <i
                                                        className="fa fa-trash fa-lg text-danger"
                                                        aria-hidden="true"
                                                        onClick={() => _handleRemove(index)}
                                                        style={{
                                                            cursor: 'pointer',
                                                        }}
                                                    ></i>
                                                )
                                            }
                                        </td>

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>

                    <div className="d-flex justify-content-end mt-3">
                        <Button type="button" onClick={submitForm} variant="primary" className="me-2">Submit</Button>
                        <Button type='button' variant="secondary" onClick={handleClose}>Close</Button>
                    </div>
                </Form>
            </Modal.Body>
            <SAlert
                msg={'Are you sure you want to save the changes?'}
                show={showAlert}
                title={'Adjustment Transaction'}
                onConfirm={processData}
                confirmBtnText='Yes, proceed'
                cancelBtnText="Cancel"
                showCancel={true}
                onCancel={() => { setShowAlert(false) }}
            />
        </Modal>
    );
};

export default CreateTransactionAdjustmentModal;
