import { SAGA_ACTIONS } from "../../constants";
import { useApiCall } from "../common/appApiCallHook";



export function useCreditCardApi() {

    const callApi = useApiCall();

    const fetchCreditCardCsvList = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CREDIT_CARD.FETCH_LIST, data, onSuccess, onError);
    }

    const fetchCreditCardCsvDetails = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CREDIT_CARD.FETCH_DETAILS, data, onSuccess, onError);
    }

    const csvUpload = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CREDIT_CARD.UPLOAD_CSV, data, onSuccess, onError);
    }

    const CCUpload = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CREDIT_CARD.CC_UPLOAD, data, onSuccess, onError);
    }

    const CCDelete = (data: any, onSuccess: Function, onError: Function) => {
        callApi(SAGA_ACTIONS.CREDIT_CARD.CC_DELETE, data, onSuccess, onError);
    }

    return {
        fetchCreditCardCsvList,
        fetchCreditCardCsvDetails,
        csvUpload,
        CCUpload,
        CCDelete
    }
}