

import { FC, useState, ReactNode, useRef } from 'react'
import { useSelector } from 'react-redux'
import Form from 'react-bootstrap/Form';
import * as yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from "react-hook-form";
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import toast from 'react-hot-toast';
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';

// import { CreditCardCsvValidate } from '../../../resources/form-validator';
import { CC_AMOUNT_TYPE, CC_DATE_FORMAT, GL_VP, SELECT_CUSTOM_STYLE } from '../../../resources/constants';
import closeBtn from '../../../assets/images/close-btn.svg';
import { CreditCardCsvI } from '../../../resources/form-props';
import SelectCustomOption from '../../../components/SelectCustomOption';
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { useCreditCardApi } from '../../../resources/hooks/api/creditCardApiHook';
import { getBranch } from '../../../store/user/selectors'

interface Props {
    closeModal: () => void
}

const CreditCardCSVUploadModal: FC<Props> = ({ closeModal }) => {

    const { glAccountsList } = useChartAccountApi();
    const { csvUpload, CCUpload } = useCreditCardApi();

    const branchData = useSelector(getBranch);
    const fileRef = useRef<HTMLInputElement>(null);

    const CreditCardCsvValidate = yup.object().shape({
        step: yup.number(),
        file: yup.mixed()
            .required('File is required')
            .test(
                "is-file-of-correct-type",
                "File is not of supported type",
                () => {
                    let valid = true;
                    const file = fileRef && fileRef?.current && fileRef?.current?.files && fileRef?.current?.files[0] || null;
                    if (file) {
                        const type = file.type.split("/")[1];
                        const validTypes = ["csv"];
                        if (!validTypes.includes(type)) {
                            valid = false;
                        }
                    }
                    return valid;
                }
            ),
        skip_rows: yup.number().typeError('Invalid skip rows').min(0, 'Skip rows must be greater than or equal to 0'),
        master_chart_of_account_id: yup.object().when(['step'], {
            is: (step: number) => {
                return step === 1;
            },
            then: (schema: any) => schema.shape({
                label: yup.string().required('Account is required'),
                value: yup.string().required('Account is required'),
            }).required('Account is required').typeError('Account is required'),
            otherwise: (schema: any) => schema.nullable()
        }),
        amount_type: yup.object().when(['step'], {
            is: (step: number) => {
                return step === 1;
            },
            then: (schema: any) => schema.shape({
                label: yup.string().required('Amount type is required'),
                value: yup.string().required('Amount type is required'),
            }).required('Amount type is required').typeError('Amount type is required'),
            otherwise: (schema: any) => schema.nullable()
        }),
        amount_col: yup.object().when(['step'], {
            is: (step: number) => {
                return step === 1;
            },
            then: (schema: any) => schema.shape({
                label: yup.string().required('Amount column is required'),
                value: yup.string().required('Amount column is required'),
            }).required('Amount column is required').typeError('Amount column is required'),
            otherwise: (schema: any) => schema.nullable()
        }),
        credit_col: yup.object().when(['step', 'amount_type'], {
            is: (step: number, amount_type: any) => {
                return step === 1 && (amount_type && +amount_type.value === 3);
            },
            then: (schema: any) => schema.shape({
                label: yup.string().required('CR/DR Definition column is required'),
                value: yup.string().required('CR/DR Definition column is required'),
            }).required('CR/DR Definition column is required').typeError('CR/DR Definition column is required'),
            otherwise: (schema: any) => schema.nullable()
        }),
        date_format: yup.object().when(['step'], {
            is: (step: number) => {
                return step === 1;
            },
            then: (schema: any) => schema.shape({
                label: yup.string().required('Date format is required'),
                value: yup.string().required('Date format is required'),
            }).required('Date format is required').typeError('Date format is required'),
            otherwise: (schema: any) => schema.nullable()
        }),
        date_col: yup.object().when(['step'], {
            is: (step: number) => {
                return step === 1;
            },
            then: (schema: any) => schema.shape({
                label: yup.string().required('Date column is required'),
                value: yup.string().required('Date column is required'),
            }).required('Date column is required').typeError('Date column is required'),
            otherwise: (schema: any) => schema.nullable()
        }),
        description_col: yup.object().when(['step'], {
            is: (step: number) => {
                return step === 1;
            },
            then: (schema: any) => schema.shape({
                label: yup.string().required('Description column is required'),
                value: yup.string().required('Description column is required'),
            }).required('Description column is required').typeError('Description column is required'),
            otherwise: (schema: any) => schema.nullable()
        })
    });

    const { control, register, watch, handleSubmit, getValues, resetField, reset, setValue, formState: { errors } } = useForm<CreditCardCsvI>({
        defaultValues: {
            step: 0,
            master_chart_of_account_id: null,
            file: null,
            skip_rows: 0,
            amount_type: null,
            amount_col: null,
            credit_col: null,
            date_format: null,
            date_col: null,
            description_col: null
        },
        resolver: yupResolver(CreditCardCsvValidate)
    });

    const [showLoader, setShowLoader] = useState<boolean>(false);
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#fff'));
    const [csvColumns, setCsvColumns] = useState<any[]>([]);
    const [formInput, setFormInput] = useState<any>({
        file: null,
        skip_rows: 0,
    });
    
    const watchAmountType = watch('amount_type', 1);

    const fechAccountList = (searchText: string = ''): Promise<any[]> => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            params['parent_account_in'] = GL_VP.CREDIT_CARD.account.join(',');
            // params['include_assigned_vp'] = 1;

            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id'],
                    account: e['account']
                }));
                return resolve(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    const filterAccountList = async (inputValue: string) => {
        const data = await fechAccountList(inputValue)
        return data;
    };

    const processData = (values: any) => {
        const formData = new FormData();
        formData.append('file', values.file);

        if (values.step === 0) {
            setShowLoader(true);

            const params = {
                skip_rows: values.skip_rows
            }

            csvUpload({ formData, params }, (message: string, resp: any) => {
                setShowLoader(false);
                const columns = resp.data.data.map((e: string) => ({ label: e, value: e }));
                setCsvColumns(columns);
                setValue('step', 1);
                toast.success(message);
            }, (message: string, resp: any) => {
                setShowLoader(false);
                toast.error(message);
            });
        } else {
            formData.append('master_chart_of_account_id', values.master_chart_of_account_id.value);
            formData.append('amount_type', values.amount_type.value);
            formData.append('amount_col', values.amount_col.value);
            formData.append('date_format', values.date_format.value);
            formData.append('date_col', values.date_col.value);
            formData.append('description_col', values.description_col.value);
            formData.append('skip_rows', values.skip_rows);

            const params: any = {
                branch_id: +branchData['id']
            }
            if (watchAmountType?.value === 3) {
                formData.append('credit_col', values.credit_col.value);
            }
            setShowLoader(true);

            CCUpload({ formData, params }, (message: string, resp: any) => {
                setShowLoader(false);
                toast.success(message);
                _handleCloseModal();
            }, (message: string, resp: any) => {
                setShowLoader(false);
                toast.error(message);
            });
        }
    }

    const _handleCloseModal = () => {
        reset();
        closeModal();
    }

    return (
        <Modal show={true} onHide={_handleCloseModal} backdrop="static" animation={false} size="lg">
            <div className="">
                <div className="">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Upload CSV File</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={_handleCloseModal}><img src={closeBtn} alt="" /></button>
                    </div>
                    <form onSubmit={handleSubmit(processData)}>
                        <div className="modal-body pb-0">
                            <div className='row align-items-center'>
                                <div className="col-sm-7">
                                    <div className="form-group">
                                        <label htmlFor="">Select Your CSV File<strong className='text-danger'>*</strong></label>
                                        <Controller
                                            control={control}
                                            name="file"
                                            render={({ field: { onChange, name, disabled } }) => (
                                                <input
                                                    type="file"
                                                    ref={fileRef}
                                                    accept=".csv"
                                                    name={name}
                                                    onChange={(e: any) => {
                                                        onChange(e.target.files[0]);
                                                        setFormInput({ 
                                                            ...formInput, 
                                                            file: e.target.files[0] 
                                                        });
                                                    }}
                                                    className="form-control"
                                                    style={{ lineHeight: '2' }}
                                                    disabled={getValues('step') === 1}
                                                />
                                            )}
                                        />
                                        {
                                            errors && errors.file && errors.file.message && (
                                                <Form.Text className="text-danger">
                                                    {errors.file.message as ReactNode || ''}
                                                </Form.Text>
                                            ) || ''
                                        }
                                    </div>
                                </div>
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label htmlFor="">Skip rows</label>
                                        <Controller
                                            control={control}
                                            name="skip_rows"
                                            render={({ field: { name, onChange} }) => (
                                                <input
                                                    name={name}
                                                    onChange={(e: any) => {
                                                        onChange(e);
                                                        setFormInput({ 
                                                            ...formInput, 
                                                            skip_rows: e.target.value 
                                                        });
                                                    }}
                                                    type="number"
                                                    placeholder='Skip rows'
                                                    className="form-control"
                                                    disabled={getValues('step') === 1}
                                                />
                                            )}
                                        />
                                        {
                                            errors && errors.skip_rows && errors.skip_rows.message && (
                                                <Form.Text className="text-danger">
                                                    {errors.skip_rows.message as ReactNode || ''}
                                                </Form.Text>
                                            ) || ''
                                        }
                                    </div>
                                </div>
                                <div className="col-md-1">
                                    {
                                        getValues('step') === 1 && (
                                            <button type="button" className="btn btn-warning" onClick={() => reset({ "step": 0, file: formInput['file'], skip_rows: formInput['skip_rows'] })}>Edit</button>
                                        )
                                    }

                                </div>
                                <span><strong>Note:</strong> <p>If the CSV header starts at the first row, can skip the input, otherwise please input the row number starting from 0.</p></span>
                            </div>

                            {
                                getValues('step') === 1 && (
                                    <div className="">
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <label htmlFor="">Select Credit Card Acc<strong className='text-danger'>*</strong></label>
                                                    <Controller
                                                        control={control}
                                                        name={`master_chart_of_account_id`}
                                                        render={({ field }) => (
                                                            <AsyncSelect
                                                                {...field}
                                                                isClearable={true}
                                                                placeholder={'Select any'}
                                                                defaultOptions
                                                                loadOptions={filterAccountList}
                                                                className=''
                                                                menuPosition='fixed'
                                                                styles={selectCustomStyle}
                                                                components={{ Option: SelectCustomOption }}
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors && errors.master_chart_of_account_id && errors.master_chart_of_account_id?.message && (
                                                            <Form.Text className="d-flex text-danger">
                                                                {errors.master_chart_of_account_id.message as ReactNode || ''}
                                                            </Form.Text>
                                                        ) || ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row m-2">
                                            <div className="col-sm-12 d-flex justify-content-around">
                                                <h5 className="text-center">Column Mapping</h5>
                                                <h5 className="text-center">CSV Header</h5>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className='form-group'>
                                                    <label htmlFor="">Define Credit Card Charge<strong className='text-danger'>*</strong></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <Controller
                                                        control={control}
                                                        name={`amount_type`}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={CC_AMOUNT_TYPE}
                                                                isClearable={true}
                                                                placeholder={'Select amount type'}
                                                                className=''
                                                                menuPosition='fixed'
                                                                styles={selectCustomStyle}
                                                                components={{ Option: SelectCustomOption }}
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors && errors.amount_type && errors.amount_type.message && (
                                                            <Form.Text className="text-danger">
                                                                {errors.amount_type.message as ReactNode || ''}
                                                            </Form.Text>
                                                        ) || ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className='form-group'>
                                                    <label htmlFor="">Credit Amount Column<strong className='text-danger'>*</strong></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <Controller
                                                        control={control}
                                                        name={`amount_col`}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={csvColumns}
                                                                isClearable={true}
                                                                placeholder={'Select amount column'}
                                                                className=''
                                                                menuPosition='fixed'
                                                                styles={selectCustomStyle}
                                                                components={{ Option: SelectCustomOption }}
                                                            />
                                                        )}
                                                    />

                                                    {/* <input type="text" className="form-control" {...register('amount_col')} placeholder='Amount column' /> */}
                                                    {
                                                        errors && errors.amount_col && errors.amount_col.message && (
                                                            <Form.Text className="text-danger">
                                                                {errors.amount_col.message as ReactNode || ''}
                                                            </Form.Text>
                                                        ) || ''
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            watchAmountType?.value === 3 && (
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className='form-group'>
                                                            <label htmlFor="">CR/DR Definition column<strong className='text-danger'>*</strong></label>
                                                        </div>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <div className="form-group">
                                                            <Controller
                                                                control={control}
                                                                name={`credit_col`}
                                                                render={({ field }) => (
                                                                    <Select
                                                                        {...field}
                                                                        options={csvColumns}
                                                                        isClearable={true}
                                                                        placeholder={'Select credit column'}
                                                                        className=''
                                                                        menuPosition='fixed'
                                                                        styles={selectCustomStyle}
                                                                        components={{ Option: SelectCustomOption }}
                                                                    />
                                                                )}
                                                            />
                                                            {
                                                                errors && errors.credit_col && errors.credit_col.message && (
                                                                    <Form.Text className="text-danger">
                                                                        {errors.credit_col.message as ReactNode || ''}
                                                                    </Form.Text>
                                                                ) || ''
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            ) || ''
                                        }

                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className='form-group'>
                                                    <label htmlFor="">Define Date Format<strong className='text-danger'>*</strong></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <Controller
                                                        control={control}
                                                        name={`date_format`}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={CC_DATE_FORMAT}
                                                                isClearable={true}
                                                                placeholder={'Select date format'}
                                                                className=''
                                                                menuPosition='fixed'
                                                                styles={selectCustomStyle}
                                                                components={{ Option: SelectCustomOption }}
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors && errors.date_format && errors.date_format.message && (
                                                            <Form.Text className="text-danger">
                                                                {errors.date_format.message as ReactNode || ''}
                                                            </Form.Text>
                                                        ) || ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className='form-group'>
                                                    <label htmlFor="">Date Column<strong className='text-danger'>*</strong></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    <Controller
                                                        control={control}
                                                        name={`date_col`}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={csvColumns}
                                                                isClearable={true}
                                                                placeholder={'Select date column'}
                                                                className=''
                                                                menuPosition='fixed'
                                                                styles={selectCustomStyle}
                                                                components={{ Option: SelectCustomOption }}
                                                            />
                                                        )}
                                                    />
                                                    {/* <input type="text" className="form-control" {...register('date_col')} placeholder='Date column' /> */}
                                                    {
                                                        errors && errors.date_col && errors.date_col.message && (
                                                            <Form.Text className="text-danger">
                                                                {errors.date_col.message as ReactNode || ''}
                                                            </Form.Text>
                                                        ) || ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <div className='form-group'>
                                                    <label htmlFor="">Description Column<strong className='text-danger'>*</strong></label>
                                                </div>
                                            </div>
                                            <div className="col-sm-6">
                                                <div className="form-group">
                                                    {/* <input type="text" className="form-control" {...register('description_col')} placeholder='Description Column' /> */}
                                                    <Controller
                                                        control={control}
                                                        name={`description_col`}
                                                        render={({ field }) => (
                                                            <Select
                                                                {...field}
                                                                options={csvColumns}
                                                                isClearable={true}
                                                                placeholder={'Select description column'}
                                                                className=''
                                                                menuPosition='fixed'
                                                                styles={selectCustomStyle}
                                                                components={{ Option: SelectCustomOption }}
                                                            />
                                                        )}
                                                    />
                                                    {
                                                        errors && errors.description_col && errors.description_col.message && (
                                                            <Form.Text className="text-danger">
                                                                {errors.description_col.message as ReactNode || ''}
                                                            </Form.Text>
                                                        ) || ''
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-cancel" onClick={_handleCloseModal}>Close</button>
                            {
                                getValues('step') === 0 && (
                                    <button type="submit" className="btn btn-primary">
                                        {showLoader && (<Spinner animation="border" size="sm" />) || 'Upload File'}
                                    </button>
                                )
                            }
                            {
                                getValues('step') === 1 && (
                                    <button type="submit" className="btn btn-primary">
                                        {showLoader && (<Spinner animation="border" size="sm" />) || 'Save changes'}
                                    </button>
                                )
                            }
                        </div>
                    </form>
                </div>
            </div>
            {/* </div> */}
        </Modal>
    )
}

export default CreditCardCSVUploadModal;