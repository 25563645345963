import { Outlet, Navigate, matchPath, matchRoutes, useLocation, useParams } from 'react-router-dom'
import { URLS } from '../../resources/constants'
import { useAccountMapped } from '../../resources/hooks/accountMappedHook'
import { useEffect, useState } from 'react';

const UserMappedRoutes = () => {
    const bankAccountMatch = useAccountMapped();
    const location = useLocation();
    const params = useParams();

    const matchExpensePath = (location: string): boolean => {
        return URLS.EXPENSE_GL_CLASSIFICATION_STATUS === location || (params['month'] && params['year'] && URLS.EXPENSE_GL_CLASSIFICATION.replace(":month", params['month']).replace(":year", params['year'])) === location
    }

    const matchRevenuePath = (location: string): boolean => {
        return URLS.REVENUE_GL_CLASSIFICATION_STATUS === location || (params['month'] && params['year'] && URLS.REVENUE_GL_CLASSIFICATION.replace(":month", params['month']).replace(":year", params['year'])) === location
    }

    const matchSalesPath = (location: string): boolean => {
        return URLS.SALES_GL_CLASSIFICATION_STATUS === location || (params['month'] && params['year'] && URLS.SALES_GL_CLASSIFICATION.replace(":month", params['month']).replace(":year", params['year'])) === location
    }

    const matchCreditCardPath = (location: string): boolean => {
        return URLS.CREDIT_CARD_GL_CLASSIFICATION_STATUS === location || (params['id'] && URLS.CREDIT_CARD_GL_CLASSIFICATION.replace(":id", params['id']).replace(":id", params['id'])) === location
    }

    return(
        matchExpensePath(location['pathname']) && bankAccountMatch ? <Outlet/> : 
        matchRevenuePath(location['pathname']) && bankAccountMatch ? <Outlet/> :
        matchCreditCardPath(location['pathname']) && bankAccountMatch ? <Outlet/> : 
        matchSalesPath(location['pathname']) /*&& mappedAcc['sales_activity_match']*/ ? <Outlet/> :
        <Navigate to={URLS.DASHBOARD} />
    )
}

export default UserMappedRoutes