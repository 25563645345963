import React, { useEffect, useState, useRef, ReactNode } from 'react'
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import Badge from 'react-bootstrap/Badge';
import toast from 'react-hot-toast';
import moment from 'moment';
import Pagination from '@mui/material/Pagination';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';

import { fileDownloadLink } from '../../../resources/functions'
import { useChartAccountApi } from '../../../resources/hooks/api/chartAccountApiHook';
import { getBranch } from '../../../store/user/selectors'
import CreditCardCSVUploadModal from '../../../components/Modal/CreditCardCSVUpload/CreditCardCSVUploadModal'

// import '../GlClassification.css'
import { useCreditCardApi } from '../../../resources/hooks/api/creditCardApiHook';
import { GL_VP, SELECT_CUSTOM_STYLE, URLS } from '../../../resources/constants';
import SelectCustomOption from '../../../components/SelectCustomOption';
import SAlert from '../../../components/SAlert';

interface Props {

}

const CreditCardGlClassificationStatus: React.FC<Props> = () => {

    const { fetchCreditCardCsvList, CCDelete } = useCreditCardApi();
    const { glAccountsList } = useChartAccountApi();
    const navigate = useNavigate();

    const branchData = useSelector(getBranch)

    const [records, setRecords] = useState<any | null>(null);
    const [isVisibleCsvUploader, setIsVisibleCsvUploader] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [page, setPage] = useState(1);
    const [searchParam, setSearchParam] = useState<any>({
        master_chart_of_account_id: null,
        status: null
    });
    const [totalRecords, setTotalRecords] = useState(1);
    const [selectCustomStyle, setSlectCustomStyle] = useState<any>(SELECT_CUSTOM_STYLE('#fff'));
    const [ showConfirm, setShowConfirm ] = useState<boolean>(false);
    const [ selectedRecordId, setSelectedRecordId ] = useState<number | null>(null);

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        setPage(value);
    };

    useEffect(() => {
        fetchList();
    }, [page])

    const fetchList = () => {
        setIsLoading(true);
        const params: any = {
            branch_id: branchData['id'],
            page: page,
            per_page: 10
        }
        if (searchParam['master_chart_of_account_id']) {
            params['master_chart_of_account_id'] = searchParam['master_chart_of_account_id']
        }
        if (searchParam['status'] !== null) {
            params['status'] = searchParam['status']
        }
        fetchCreditCardCsvList(params, (message: string, resp: any) => {
            setIsLoading(false);
            setRecords(resp.data.data.items);
            setTotalRecords(resp.data.data.total);
        }, (message: string, resp: any) => {
            setIsLoading(false);
            toast.error(message);
        })
    }

    const fechAccountList = (searchText: string = ''): Promise<any[]> => {
        return new Promise<any>((resolve, reject) => {
            const params: any = {
                "page": 1,
                "per_page": 10,
                branch_id: +branchData['id']
            }
            if (searchText) {
                params['name'] = searchText;
            }
            params['parent_account_in'] = GL_VP.CREDIT_CARD.account.join(',');

            glAccountsList(params, (message: string, resp: any) => {
                const data = resp.data.data.result.map((e: any) => ({
                    label: e['name'] + `(${e['account']})`,
                    value: e['id'],
                    account: e['account']
                }));
                return resolve(data);
            }, (message: string, resp: any) => {
                toast.error(message);
                return resolve([]);
            })
        })
    }

    const filterAccountList = async (inputValue: string) => {
        const data = await fechAccountList(inputValue)
        return data;
    };

    const _handleDownloadCsv = (url: string, fileName: string) => {
        fileDownloadLink(url, fileName);
    }

    const hideCsvUploader = () => {
        setIsVisibleCsvUploader(false);
        fetchList();
    }

    const _handleChangeSearchParam = (name: string, e: any) => {
        console.log('e: ', e)
        setSearchParam({
            ...searchParam,
            [name]: e ? e.value : null
        })
    }

    const _handleConfirm = (id: number) => {
        setShowConfirm(true);
        setSelectedRecordId(id);
    }

    const _handleDeleteRecord = () => {
        setIsLoading(true);
        CCDelete({ id: selectedRecordId, branch_id: branchData.id}, (message: string, resp: any) => {
            toast.success(message);
            setShowConfirm(false);
            setIsLoading(false);
            fetchList();
        }, (message: string, resp: any) => {
            toast.error(message);
            setIsLoading(false);
        })
    }


    return (
        <div className="main-container flex-grow-1">
            <div className="container-fluid">
                <div className="pb-4 pt-3" id="ux_page_title">
                    <div className="align-items-center d-flex justify-content-between">
                        <div className="align-items-center d-flex page-title">
                            <h1 className="h3 font-weight-700 mb-0 d-inline-flex">Credit Card Classification Status</h1>
                        </div>
                        <div className="">
                            <button type="button" className="btn btn-primary" onClick={() => setIsVisibleCsvUploader(true)}>Upload New Credit Card CSV</button>
                        </div>
                    </div>
                </div>

                <div className="filter-box-top">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="filter-wrap d-flex justify-content-between align-items-center">
                                <div className="d-flex flex-grow-1">
                                    <div className="filter-box w-25">
                                        <AsyncSelect
                                            isClearable={true}
                                            placeholder={'Select account'}
                                            defaultOptions
                                            loadOptions={filterAccountList}
                                            className={'zindex-10'}
                                            menuPosition='fixed'
                                            onChange={(e: any) => _handleChangeSearchParam('master_chart_of_account_id', e)}
                                            styles={selectCustomStyle}
                                            components={{ Option: SelectCustomOption }}
                                        />
                                    </div>
                                    <div className="filter-box w-25">
                                        <Select
                                            isClearable={true}
                                            options={[
                                                { label: 'Pending Classification', value: 0 },
                                                { label: 'Classified', value: 1 }
                                            ]}
                                            placeholder={'Select status'}
                                            className={'zindex-10'}
                                            menuPosition='fixed'
                                            onChange={(e: any) => _handleChangeSearchParam('status', e)}
                                            styles={selectCustomStyle}
                                            components={{ Option: SelectCustomOption }}
                                        />
                                    </div>

                                    <div className="filter-btn">
                                        <button type="button" onClick={() => fetchList()}> Search &nbsp; <i className="fa fa-search" aria-hidden="true"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className=" executive-dashboard">
                    <div className="dashboard-top-part">
                        <div className={`page-loader ${isLoading ? 'visible' : ''}`}>
                            <div className="loader"></div>
                        </div>
                        <div className="table-responsive list-table table-scroll">
                            <table className="table table-default">
                                <thead>
                                    <tr className="align-middle">
                                        <th className="text-center">Date Uploaded</th>
                                        <th className="text-center">Credit Acc</th>
                                        <th className="text-center">CSV File Name </th>
                                        <th className="text-center">Status</th>
                                        <th className="text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        records && records.map((data: any, index: number) => (
                                            <tr className="align-middle h-60" key={index}>
                                                <td className="text-center">
                                                    {moment(data['created_at']).format('Do MMM, YYYY')}
                                                </td>
                                                <td className="text-center">
                                                    {data['name'] + ' (' + data['account'] + ')'}
                                                </td>
                                                <td className="text-center">
                                                    {data['file_name']} &nbsp;
                                                    <i className="fa fa-download cursor-pointer" title='Download' onClick={() => _handleDownloadCsv(data['file_path'], data['file_name'])}></i>
                                                </td>
                                                <td className="text-center">
                                                    <h6>
                                                        {data['status'] == 0 ? <Badge bg="warning" className='p-2'>Pending Classification</Badge> : <Badge bg="success">Classified</Badge>}
                                                    </h6>
                                                </td>
                                                <td className="text-center">
                                                    {/* {
                                                        data['status'] == 0 && <Link to={`/credit-card-gl-classification/${data.id}`}>Click Here To Classify</Link> || <Link to={`/credit-card-gl-classification/${data.id}`}>View Table</Link>
                                                    } */}
                                                    {
                                                        data['status'] == 0 && <button type='button' className='btn btn-primary btn-sm' onClick={() => navigate(`/credit-card-gl-classification/${data.id}`)}>Classify</button> || <button type='button' className='btn btn-primary btn-sm' onClick={() => navigate(`/credit-card-gl-classification/${data.id}`)}>View Table</button>
                                                    }
                                                    &nbsp;
                                                    <button type='button' className='btn btn-danger btn-sm' onClick={() => _handleConfirm(data.id)}>Remove</button>
                                                </td>
                                                <td></td>
                                            </tr>))
                                    }
                                    {
                                        records && records.length == 0 && (
                                            <tr>
                                                <td colSpan={5} className='text-center'>
                                                    <p>You have no records to classify.</p>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <Pagination count={Math.ceil(totalRecords / 10)} page={page} onChange={handleChange} />
                        </div>
                    </div>
                </div>
                {
                    isVisibleCsvUploader && <CreditCardCSVUploadModal closeModal={hideCsvUploader} />
                }
                <SAlert
                    show={showConfirm}
                    icon={'warning'}
                    showCancel={true}
                    confirmBtnText={'Yes, delete it!'}
                    cancelBtnText={"No"}
                    confirmBtnBsStyle={'danger'}
                    cancelBtnBsStyle={"secondary"}
                    title={"Are you sure?"}
                    msg={"You won't be able to revert this!"}
                    onConfirm={() => _handleDeleteRecord()}
                    onCancel={() => setShowConfirm(false)}
                />
            </div>
        </div>

    )
}

export default CreditCardGlClassificationStatus;